<template>
  <div id="dashboard-component">
    <fieldset :class="['upload-component', { isOpen: isOpen }]">
        <legend><button class="button" @click="openUpload">Partager vos photos</button></legend>
        <div class="upload">
          <form ref="formUpload" @submit.prevent="submitForm">
            <div class="form-group">
              <label for="username">Votre nom *</label>
              <input type="text" name="username" v-model="user.name" required="true"/>
            </div>
            <div class="container">
              <div class="form-group column">
                <label for="email">Email</label>
                <input type="email" name="email" v-model="user.email"/>
              </div>
              <div class="column andOr"> ET/OU </div>
              <div class="form-group column">
                <label for="phoneNumber">Votre téléphone</label>
                <input type="tel" name="phoneNumber" v-model="user.phoneNumber"/>
              </div>
            </div>
            <div class="form-group">

              <input type="checkbox" id="saveLocal" name="saveLocal" v-model="saveLocal"/><label for="saveLocal" class="checkbox">Se souvenir de moi</label>
            </div>
          </form>

          <Dashboard
              v-if="uppy!=null"
              ref="dash"
              :uppy="uppy"
              :locale="locale"
              :props="{
                metaFields: [
                  {
                    id: 'name',
                    name: 'Nom du fichier',
                    placeholder: 'Nom du fichier'
                  },
                  {
                    id: 'caption',
                    name: 'Description',
                    placeholder: 'Décrivez ce que représente l\'image ou écrivez un commentaire',
                  },
                ],
                locale: locale,
                proudlyDisplayPoweredByUppy: false,
                allowMultipleUploadBatches: false,
            }"
              :plugins="['Webcam', 'ImageEditor']"
          />
        </div> <!-- /v-if="open" -->
    </fieldset>
  </div>
</template>

<script>
import {  Dashboard } from "@uppy/vue";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";
import "@uppy/image-editor/dist/style.css";

import Uppy from "@uppy/core";
import Webcam from "@uppy/webcam";
import XHRUpload from "@uppy/xhr-upload";
import ImageEditor from "@uppy/image-editor";
import French from '@uppy/locales/lib/fr_FR';
import albumUserService from "@/service/albumUserService";
import { watch } from "vue";



const locale = {
  ...French,
  strings: {
    ...French.strings,
    uploadXFiles: {
      '0': 'Envoyer %{smart_count} fichier',
      '1': 'Envoyer %{smart_count} fichiers',
    },
  }
};


export default {
  name: "App",
  components: {
    Dashboard,
  },
  props: {
    albumId: String,
  },
  data: () => {
    return {
      isOpen: false,
      uppy : null,
      locale,
      user : {
        id: null,
        name: "",
        email: "",
        phoneNumber: "",
      },
      saveLocal: false
    };
  },
  methods: {

    isFormValid() {
      const form = this.$refs.formUpload;
      return form.checkValidity();
    },
    checkUploadEnabled(){
      const dashboard = this.uppy.getPlugin('Dashboard');
      if(dashboard){
        if(this.isFormValid()){
          dashboard.setOptions({ disabled: false });
        }else{
          dashboard.setOptions({ disabled: true });
        }
      }
    },
    addUserInfoOnFiles() {
      let files = this.uppy.getFiles();
      files.forEach(file => {
        this.uppy.setFileMeta(file.id, {
          ...file.meta,
          albumUserId: this.user.id,
          username: this.user.name
        });
      });
    },
    initUppy() {
      this.uppy = new Uppy({
        restrictions: {
            allowedFileTypes: ['image/*', 'video/*'] // Allowed file types
        },
        allowMultipleUploadBatches: false,
      });

      //this.uppy.getPlugin('Dashboard').setOptions({ disabled: true });
      // Ajouter un hook avant le téléchargement
      this.uppy.addPreProcessor(() => {
        if(this.isFormValid){

          if (this.user.id){
            // L'utilisateur est déjà enregistré
            this.addUserInfoOnFiles();
            return Promise.resolve();
          } else {
            // On créé l'utilisateur avant d'uploader les fichiers
            return albumUserService.save(this.albumId, this.user)
              .then((user) => {
                this.user.id = user.id;

                this.addUserInfoOnFiles();

                return Promise.resolve();
              })
              .catch((error) => {
                return Promise.reject(error);
              });
          }
        }else{
          return Promise.reject("Formulaire invalide");
        }
      });

      this.uppy.use(XHRUpload, {
        // TODO: mettre à jour le endpoint
        endpoint: "/api/album/"+this.albumId+"/medias",
        fieldName: "file",
        async body (file) {
          /* eslint-disable no-debugger */
          debugger;
          const formData = new FormData();
          formData.append('file', file.data, file.name);
          formData.append('albumUserId', this.user.id);
          formData.append('username', this.user.name);
          return formData;
        },
      });


      this.uppy.on('beforeUpload', (files) => {
          /* eslint-disable no-debugger */
          debugger;
        Object.keys(files).forEach(fileID => {
          const file = files[fileID];
          this.uppy.setFileMeta(fileID, {
            ...file.meta,
            additionalField: 'valeur dynamique', // Ajoutez un champ personnalisé
            autreChose: 'autre valeur'
          });
        });
      });

      this.uppy.use(Webcam);
      this.uppy.use(ImageEditor, {
        quality: 0.8,
      });

       // Écouter l'événement 'upload-success'
       this.uppy.on('complete', () => {
        this.isOpen = false;
        this.uppy.clear();
        if(this.saveLocal){
          localStorage.setItem('user', JSON.stringify(this.user));
        }else{
          localStorage.removeItem('user');
        }
        this.$emit('upload-complete');
      });

      this.uppy.on("file-added", () => {
        this.checkUploadEnabled();
      });
    },
    openUpload() {
      this.isOpen = !this.isOpen;
    }
  },
  mounted() {
    const localUser = localStorage.getItem('user');
    if(localUser){
      this.user = JSON.parse(localUser);
      this.saveLocal = true;
    }
    this.initUppy();

    // On démarre le watch qu'une fois le user restauré
    watch(this.user,() => { 
        this.checkUploadEnabled();
        this.user.id = null;
      },
      { deep: true } // Permet de regarder les changements dans les propriétés internes de l'objet
    );
  }
};
</script>

<style>

#dashboard-component {
  width: 100%;

}
.uppy-Dashboard-inner {
  width: 100% !important;
  max-width: 100%;
  max-height: 300px;
}


@media screen and (max-width: 468px) {
  .column.andOr {
    margin: 0;
    margin-top: -15px;
    padding: 0;
  }
}

@media screen and (min-width: 468px) {
  #dashboard-component {
    max-width: 600px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 800px) {
  #dashboard-component {
    max-width: 800px;
  }
}

fieldset.upload-component {

  border: none;

  legend {
      padding: 0; /* Espacement autour du texte de la légende */
      border: 0; /* Bordure autour de la légende */
      border-radius: 5px; /* Coins arrondis de la légende */
  }



  .upload{
    display: none;
  }
}


fieldset.upload-component.isOpen {

  border: 2px solid #4a90e2; /* Bordure du fieldset */
  border-radius: 10px; /* Coins arrondis */
  padding: 20px; /* Espacement à l'intérieur */
  background-color: #ffffff; /* Couleur de fond */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre pour l'effet de profondeur */
  margin: 10px;

  legend {
  }

  .upload{
    display: block;
  }
}

</style>
