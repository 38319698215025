<template>
  
  <div v-if="albums && albums.length>0">
    <h3>Mes derniers albums</h3>
    <p v-for="album in albums" :key="album.id">
        <a :href="baseUrl+'/album/'+album.id+'?secureToken='+album.securityToken">{{album.name}}</a>
    </p>
  </div>
  <div class="container marginTop">
    <div class="column">

      <form @submit.prevent="submitForm" class="formCreate" v-if="resultForm == null">
        <div class="form-group">
          <label for="nameAlbum">Nom de l'album *</label>
          <input type="text" id="nameAlbum" v-model="form.name" required />
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <input type="text" id="description" v-model="form.description" />
        </div>
        <hr class="separator"/>
        <div class="form-group">
          <label for="email">Email *</label>
          <input type="email" id="email" v-model="form.email" required />
        </div>
        <div class="form-group">
          <label for="phoneNumber">Numéro de téléphone</label>
          <input type="tel" id="phoneNumber" v-model="form.phoneNumber" />
        </div>
        <p>L'album photo est conservé pendant 90 jours après sa création</p>
        <button type="submit" class="button">Créer l'album</button>
      </form>
      <div v-else>
        <p>Votre album <span class="nameAlbum">"{{resultForm.name}}"</span> a été créé !</p>
        <p class="titleLink">Vous pouvez accéder et partager votre album via le lien suivant :</p>
        <div class="boxLink">
          <a class="link" :href="baseUrl+'/album/'+resultForm.id">{{baseUrl}}/album/{{resultForm.id}}</a>
        </div>
        <p class="titleLink">Le lien pour administrer votre album (ne le partagez pas):</p>
        <div class="boxLink">
          <a class="link" :href="baseUrl+'/album/'+resultForm.id+'?secureToken='+resultForm.securityToken" target="photo-admin">{{baseUrl}}/album/{{resultForm.id}}?secureToken={{resultForm.securityToken}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {onMounted, ref} from 'vue';
import albumService from '@/service/albumService';


const form = ref({
  nameAlbum: '',
  description: '',
  name: '',
  email: '',
  phoneNumber: ''
});

interface AlbumResult {
  id: string;
  name: string;
  description: string;
  creationDate: string;
  lastModifiedDate: string;
  securityToken: string;
}

const resultForm = ref<AlbumResult | null>(null);
const baseUrl = window.location.origin;
let albums = ref<AlbumResult[]>([]);

const submitForm = async () => {
  resultForm.value = await albumService.create(form.value);
  albums.value.push(resultForm.value as AlbumResult);
  sessionStorage.setItem('createdAlbums', JSON.stringify(albums.value));
};



onMounted(() => {
  let createdAlbums = sessionStorage.getItem('createdAlbums');
  if(createdAlbums){
    albums.value= JSON.parse(createdAlbums);
  }
})
</script>

<style scoped>
.container {
  border: 2px solid #4a90e2; /* Bordure du fieldset */
  border-radius: 10px; /* Coins arrondis */
  padding: 20px; /* Espacement à l'intérieur */
  background-color: #ffffff; /* Couleur de fond */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre pour l'effet de profondeur */
  max-width: 600px;
  margin: auto;
}

.marginTop {
  margin-top: 30px;
}

.formCreate {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.nameAlbum {
  font-weight: bold;
}

.boxLink {
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
}

.titleLink {
  margin-top: 30px;
  font-size: 12px;
}

.link {
  color: #333;
  word-break: break-all;
}
</style>
