import Album from "@/types/Album";
import Info from "@/types/Info";
import { directusClient, MySchema } from "@/utils/DirectusUtils";
import { readItem, readItems } from "@directus/sdk";
import axios from "axios";

export default {
    // Récupérer tous les items d'une collection
    get() : Promise<Info>{
     const params = new URLSearchParams();
     
     return axios
       .get(`/api/info`, {
         params: params
       })
       .then(response => {
         return response.data;
       })
       .catch(error => {
         console.error('Erreur lors de la récupération des photos:', error);
         return Promise.reject(error);
       });
    }
  };