import AlbumMedia from "@/types/AlbumMedia";
import AlbumUser from "@/types/AlbumUser";
import Page from "@/types/Page";
import { directusClient } from "@/utils/DirectusUtils";
import { aggregate, createItem, readItem, readItems } from "@directus/sdk";
import axios from "axios";

export default {
    // Récupérer tous les items d'une collection
    save(albumId: string, albumUser: AlbumUser) : Promise<AlbumUser[]> {
      return axios
        .post(`/api/album/${albumId}/user`, albumUser)
        .then(response => {
          // Supposons que la réponse renvoie une structure avec les photos
          return response.data; // Accéder aux photos depuis la réponse paginée
        })
        .catch(error => {
          console.error('Erreur lors du save du user', error);
          return Promise.reject(error);
        });
    },
    
}