<template>
  <div v-if="albumId != null">
    <h1 v-if="album!=null">{{  album.name }}</h1>

    <div v-if="secureToken">
      <p>Vous êtes connecté en tant qu'administrateur de cet album.</p>
      <p>Voici le lien pour partager votre album</p>
      <a class="link" :href="baseUrl+'/album/'+albumId">{{baseUrl}}/album/{{albumId}}</a>
    </div>
    <UploadComponent :albumId="albumId as string" @upload-complete="refresh"/>
    <button @click="refresh" class="button refresh"><i class="fa-solid fa-rotate"></i> Rafraichir</button>
    <div ref="gallery" class="gallery" :style="{'width' : galleryWidth+'px'}">
      <template v-for="(media) in medias" :key="media.id">
        <a :href="media.largeThumbUrl" class="action" v-if="media.mediaType.indexOf('image/') >= 0" :data-download-url="media.mediaCacheUrl">
          <img :src="media.thumbUrl" :alt="media.alt" :width="thumbnailWidth+'px'"/>
        </a>
        <div v-if="media.mediaType.indexOf('video/') >= 0"
          :data-video="getVideoData(media.mediaCacheUrl)"
          :data-poster="media.thumbUrl" class="action"
        >
          <img :src="media.thumbUrl" :alt="media.alt" />
        </div>
        <div class="fa fa-remove removeMedia" @click.stop="removeMedia(media.id)" v-if="secureToken"></div>
      </template>
    </div>
    <div v-if="loading" class="loading">Loading...</div>
    <button class="up" @click="upPage"><i class="fa-solid fa-arrow-up"></i></button>
  </div>
</template>

<script lang="ts" setup>

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-video.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import UploadComponent from "@/components/UploadComponent.vue";
import albumMediaService from '@/service/albumMediaService';
import { useRoute } from 'vue-router';
import Album from '@/types/Album';
import albumService from '@/service/albumService';
import AlbumMedia from '@/types/AlbumMedia';
import infoService from '@/service/infoService';
import Info from '@/types/Info';


interface lightGalleryImage extends AlbumMedia {
  thumbUrl : string;
  largeThumbUrl: string;
  mediaType: string;
  alt: string;
}


const medias = ref<lightGalleryImage[]>([]);
const loading = ref(false);
const hasMore = ref(true);
const gallery = ref<any>(null);

let info = ref<Info | undefined>(undefined);

/* eslint-disable no-unused-vars */
let lightGalleryInstance: any = null;

let since : string | null = null;
let until : string | null = null;
let lastHandleScroll = 0;

const route = useRoute();

const albumId : string = getAlbumIdFromRoute();
let album : Album | null = null;
const baseUrl = window.location.origin;

let galleryWidth = ref<number>(0);
let thumbnailWidth = ref<number>(0);

let secureToken = ref<string | undefined>(undefined)

determineWidth();

function getAlbumIdFromRoute(): string {
  const albumId = route.params.id;

  // Vérifier si albumId est défini et est une chaîne
  if (typeof albumId === 'string') {
    return albumId;
  } else {
    // Lancer une exception si albumId n'est pas une chaîne
    throw new Error(`Invalid albumId: expected a string but got ${typeof albumId}`);
  }
}

function determineWidth(){
  galleryWidth.value = (Math.trunc(window.innerWidth / 100) * 100);

  let windowWidth = window.innerWidth;
  let width = 400;
  let nbImagesPerRow = 2
  width = Math.trunc((windowWidth - 10) / nbImagesPerRow);
  width = Math.trunc((galleryWidth.value - 10) / nbImagesPerRow);
  if (windowWidth < 550) {
    //Petit écran type téléphone
    width = Math.round(width / 50) * 50; 
  } else {
    // A partir de 500px de large, on affiche autant d'image que possible par ligne, avec un minimum de 300px par image
    // Ajuster nbImagesPerRow pour avoir un minimum de 300px par image
    nbImagesPerRow = Math.trunc(windowWidth / 300);
    width = Math.trunc((windowWidth / nbImagesPerRow)*0.96);
    width = Math.round(width / 100) * 100; // ON ne fait que des multiples de 100 pour éviter d'avoir trop d'image diffférence en cache
  }
  thumbnailWidth.value = width;
  console.log('nbImagesPerRow', nbImagesPerRow);

  galleryWidth.value = Math.trunc((width+10) * nbImagesPerRow);
}

function convertAlbumMediaToLightGalleryImage(media: AlbumMedia): lightGalleryImage {

  // Pour les images, flyimg va service de cache
  let largeThumbUrl = info?.value?.flyimgDomainUrl+'/upload/q_80,ao_1/'+encodeURIComponent(media.mediaUrl);

  return {
    ...media,
    thumbUrl: info?.value?.flyimgDomainUrl+'/upload/q_50,ao_1,w_'+thumbnailWidth.value+',/'+encodeURIComponent(media.mediaUrl),
    largeThumbUrl: largeThumbUrl,
    alt: ''
  };
}

/**
 * Charge les photos de l'album créé depuis le dernier refresh
 */
async function loadRecentImages(albumId: string) {
  let photos = await albumMediaService.get(albumId, since, null, null);
  if(photos.length>0){
    medias.value = [...photos.map(photo => convertAlbumMediaToLightGalleryImage(photo)), ...medias.value];
    since = photos[0].id;
    if(until == null) {
      until = photos[photos.length - 1].id;
    }

    nextTick(() => {
      initializeGallery();
    });
  }
}

/**
 * Charge les photos de l'album jusqu'à la plus ancienne
 */
async function loadOldImages(albumId: string) {
  if (loading.value || !hasMore.value) return;
  loading.value = true;

  try {    
    let photos = await albumMediaService.get(albumId, null, until, 6);

    if (photos.length > 0) {
      medias.value = [...medias.value, ...photos.map(photo => convertAlbumMediaToLightGalleryImage(photo))];

      if(since == null) {
        since = photos[0].id;
      }
      until = photos[photos.length - 1].id;
      
      // Attends que le DOM soit mis à jour avant d'initialiser ou de rafraîchir lightGallery
      nextTick(() => {
        initializeGallery();
        checkMediaFullScreen();
      });
      
    } else {
      // Il n'y a plus de photos à charger dans le passé
      hasMore.value = false;
    }
  } catch (error) {
    console.error('Error loading images:', error);
  } finally {
    loading.value = false;
  }
}

function initializeGallery() {
  destroyGallery();
  if (gallery.value) {
    lightGalleryInstance = lightGallery(gallery.value, {
      plugins: [lgThumbnail, lgZoom, lgVideo],
      speed: 500,
      mode: "lg-fade",
      thumbnail: true,
      download: true,
      selector: '.action',
      //,videojs: true
    });
  }
}

function destroyGallery() {
  if(lightGalleryInstance){
    lightGalleryInstance.destroy();
    lightGalleryInstance = null;
  }
}

function handleScroll() {
  if(new Date().getTime() - lastHandleScroll > 1000) {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    const scrollPercentage = (scrollTop + windowHeight) / documentHeight * 100;

    if (scrollPercentage >= 80) {
      console.log("handleScroll : load more images");
      lastHandleScroll = new Date().getTime();
      loadOldImages(albumId);
    }
  }
}

/**
 * Function qui vérifie si la liste des images occupe tout l'écran, si ce n'est, on continue de charger les images
 */
function checkMediaFullScreen(){
  if (window.innerWidth <= document.documentElement.clientWidth) {
    // Il n'y a pas encore scroll horizontal, on continue de charger les images
    console.log("checkMediaFullScreen : load more images");
    loadOldImages(albumId);
    return;
  }
}

function refresh() {
  loadRecentImages(albumId);
}

function loadAlbum(){
  albumService.get(albumId).then((a) => {
    album = a;
  });
}

function getVideoData(videoUrl: string) {
  return JSON.stringify({
    source: [{ src: videoUrl, type: 'video/mp4' }],
    attributes: {
      preload: false,
      playsinline: true,
      controls: true
    }
  });
}

 function removeMedia(mediaId: string) {
  if(secureToken.value){
    albumMediaService.delete(albumId, mediaId, secureToken.value).then(() => {
      medias.value = medias.value.filter(media => media.id !== mediaId);
    });
  }
}

onBeforeUnmount(() => {
  destroyGallery();
});

onMounted(() => {
  if(route.query.secureToken){
    secureToken.value = route.query.secureToken as string;
  }

  infoService.get().then((infoDto) => {
    info.value = infoDto as Info;
    loadAlbum();
    loadOldImages(route.params.id as string);
  });

  window.addEventListener('scroll', handleScroll);

});

function upPage() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

</script>

<style>
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: auto;
}

.gallery a, .gallery div {
  margin: 0;
  padding: 0;
}

.gallery img {
  max-width: 600px;
  height: auto;
  cursor: pointer;
}

.loading {
  text-align: center;
  margin: 20px 0;
}

.up {
  cursor: pointer;
  position: fixed;
  bottom: 15px;
  right: 15px;
  padding: 1rem;
  border: none;
  background-color: #000;
  color: #fff;
}

.removeMedia{
  position: relative;
  color: red;
  padding: 5px;
  cursor: pointer;
  font-size: 25px;
  height: 25px;
  display: block;
  left: -31px;
}

</style>