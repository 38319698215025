import { createWebHistory, createRouter } from 'vue-router'
import HomePage from "@/views/HomePage.vue";
import AlbumPage from '@/views/AlbumPage.vue';
import NotFound from '@/views/NotFound.vue';
import AlbumCreate from '@/views/AlbumCreate.vue';

const routes = [
    {
        name: "HomePage",
        path: "/",
        component: HomePage,
    },
    {
        name: "AlbumPage",
        path: "/album/:id",
        component: AlbumPage,
    },
    {
        name: "AlbumCreate",
        path: "/album/create",
        component: AlbumCreate,
    },
    // Route wildcard pour capturer toutes les pages inconnues
    { path: '/:pathMatch(.*)*', component: NotFound }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
