import Album from "@/types/Album";
import { directusClient, MySchema } from "@/utils/DirectusUtils";
import { readItem, readItems } from "@directus/sdk";
import axios from "axios";
import AlbumCreation from "@/types/AlbumCreation";

export default {
    // Récupérer tous les items d'une collection
    get(albumId: string) : Promise<Album>{
     const params = new URLSearchParams();

     return axios
       .get(`/api/album/${albumId}`, {
         params: params
       })
       .then(response => {
         return response.data;
       })
       .catch(error => {
         console.error('Erreur lors de la récupération des photos:', error);
         return Promise.reject(error);
       });
    },
    create(albumCreation : AlbumCreation) : Promise<any>{
        return axios
            .post(`/api/album`, albumCreation).then(response => {
                return response.data;
            })
            .catch(error => {
                console.error('Erreur lors de la création de l\'album:', error);
                return Promise.reject(error);
            })
    }
  };
