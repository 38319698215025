<template>
  <HeaderComponent />
  <router-view />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HeaderComponent from './components/HeaderComponent.vue';

@Options({
  components: {
    HeaderComponent,
  },
})
export default class App extends Vue {}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}



.form-group {
  margin-bottom: 15px;

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  label.checkbox {
      display: inline-block;
      margin-left: 5px;
  }
  

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="tel"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  input[type="text"]:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.25);
  }
}

.container {
    flex-direction: column; /* Les colonnes s'empilent verticalement */
    gap: 10px; /* Réduit l'espace entre les colonnes */

    .column {
        flex: 1; /* Chaque colonne prend une largeur égale */
        padding: 5px;
    }
}

@media (min-width: 468px) {
    .container {
      flex-direction: row;
      display: flex;
      gap: 20px; /* Espace entre les colonnes */

      .column {
          flex: 1; /* Chaque colonne prend une largeur égale */
          padding: 20px;
      }
    }
}

.button {
  margin: 10px 0;
  padding: 10px 20px;
  border: none;
  background-color: #4ea8de;
  color: #fff;
  border-radius: 3px;

  i {
    margin-right: 10px;
  }
}

.button:hover {
    background-color: #3b7aae;
    cursor: pointer;
}

</style>