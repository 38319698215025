<template>
  <header>
    
    <a class="header-left" href="/">
      <img src="../assets/tropevent-carre.png" alt="Logo Tropevent">
      <h1 class="title">Tropevent Album</h1>
    </a>
    <div ref="createButtonWrapper">
      <router-link class="button linkCustom" to="/album/create">Créer un Album</router-link>
    </div>
    <!--
    <div class="menu">
      <div class="menu-icon" @click="openMenu">
        <i class="fa-solid fa-bars"></i>
      </div>
      <nav ref="menu">
        <ul>
          <li>
            <a href="#">Lien A</a>
          </li>
          <li>
            <a href="#">Lien B</a>
          </li>
          <li>
            <a href="#">Lien C</a>
          </li>
        </ul>
      </nav>
    </div>-->
  </header>
</template>

<script setup>
import {defineComponent, onBeforeUnmount, onMounted, ref} from "vue";

defineComponent({
  name: 'HeaderComponent'
})

const createButtonWrapper = ref(null);

const updateButtonText = () => {
  const link = createButtonWrapper.value.querySelector('.linkCustom');
  if (window.innerWidth < 440) {
    link.innerText = '+';
  } else {
    link.innerText = 'Créer un Album';
  }
};

onMounted(() => {
  updateButtonText();
  window.addEventListener('resize', updateButtonText);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateButtonText);
});
/*
const menu = ref(null)

function openMenu() {
  // Get menu with ref
  // Toggle menu
  console.log("test", menu.value.classList)
  menu.value.classList.toggle('open')
}
  */
</script>

<style lang="scss" scoped>

header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  .header-left {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-decoration: none;
  }

  .title {
    display: flex;
    padding-left: 10px;
    width: 100%;
    font-size: 1.5rem;
    color: #4ea8de;
  }

  img {
    max-width: 100px;
    max-height: 50px;
  }

  .menu {
    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: center;
    gap: 2rem;
    .menu-icon {
      cursor: pointer;
      font-size: 2rem;
    }
    nav {
      display: none;
      &.open {
        display: block;
      }
      ul {
        display: flex;
        flex-flow: row nowrap;
        list-style: none;

        li {
          padding: 1rem;
          a {
            text-decoration: none;
            color: black;
          }
        }
      }
    }
  }

  .linkCustom {
    text-decoration: none;
    margin-right: 5px;
  }
}
</style>
