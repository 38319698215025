import AlbumMedia from "@/types/AlbumMedia";
import Page from "@/types/Page";
import { directusClient } from "@/utils/DirectusUtils";
import { aggregate, createItem, readItem, readItems } from "@directus/sdk";
import axios from "axios";

export default {
    // Récupérer tous les items d'une collection
    get(albumId: string, since: string | null = null, until: string | null = null, size: number | null) : Promise<AlbumMedia[]> {

      const params = new URLSearchParams();
      if(since) params.append('since', since);
      if(until) params.append('until', until);
      if(size) params.append('size', size.toString());
      params.append('sort', 'id');
      params.append('direction', 'DESC');
      
      return axios
        .get(`/api/album/${albumId}/medias`, {
          params: params
        })
        .then(response => {
          // Supposons que la réponse renvoie une structure avec les photos
          return response.data; // Accéder aux photos depuis la réponse paginée
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des photos:', error);
          return Promise.reject(error);
        });
    },
    delete(albumId: string, mediaId: string, secureToken: string) : Promise<void> {
      return axios
        .delete(`/api/album/${albumId}/medias/${mediaId}?secureToken=${secureToken}`)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.error('Erreur lors de la suppression de la photo:', error);
          return Promise.reject(error);
        });
    }
    
}